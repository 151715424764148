function loadScript(url, callback)
{
    // Adding the script tag to the head as suggested before
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;

    // Fire the loading
    head.appendChild(script);
}

function generateCaptcha() {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSYTZabcdefghijklmnopqrstyz",
        captchaNumber = 5,
        i,
        externalValue = "",
        values = "";
    for (i = 0; i < captchaNumber; i++) {
        values = Math.floor(Math.random() * chars.length);
        externalValue += chars.substring(values, values + 1);
    }
    return externalValue;
}

function setupCaptcha(textCaptcha, reloadButton) {
    textCaptcha.text(generateCaptcha());
    reloadButton.click(function(e) {
        e.preventDefault()
        textCaptcha.val('')
        textCaptcha.text(generateCaptcha())
    })
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
