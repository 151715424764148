var icon = '/images/icon/markers.png';
var mapStyle = [
    {"featureType": "administrative.province", "elementType": "labels.text.fill", "stylers": [{"color": "#434242"}]
    }, {"featureType": "administrative.locality", "elementType": "labels.text.fill", "stylers": [{ "color": "#434242" }]
    }, {"featureType": "administrative.neighborhood", "elementType": "labels.text.fill", "stylers": [{"color": "#434242"}]
    }, {"featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{"color": "#f2efe2"}]
    }, {"featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{"color": "#ffffff"}]
    }, {"featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{"visibility": "off"}]
    }, {"featureType": "road.highway.controlled_access", "elementType": "geometry.fill", "stylers": [{"color": "#e59c03"}]
    }, {"featureType": "road.local", "elementType": "geometry.fill", "stylers": [{"color": "#ffffff"}]
    }, {"featureType": "road.local", "elementType": "geometry.stroke", "stylers": [{"color": "#dfa200"}, {"invert_lightness": true}]
    }, {"featureType": "transit.line", "elementType": "all", "stylers": [{"visibility": "off"}]
    }, {"featureType": "water", "elementType": "all", "stylers": [{"hue": "#0073ff"}]
    }, {"featureType": "water", "elementType": "geometry.fill", "stylers": [{"color": "#a4cff0"}]
    }
];

function setupMap(mapId, linkDirection, lat, lng, title) {
    var latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    var map = new google.maps.Map(document.getElementById(mapId), {
        zoom: 16,
        center: latLng,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: true,
        styles: mapStyle
    });

    var marker = new google.maps.Marker({
        position: latLng,
        icon: icon,
        title: title,
        animation: google.maps.Animation.DROP,
    });

    marker.setMap(map);
    marker.addListener('click', toggleBounce);

    function toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(google.maps.Animation.BOUNCE);
        }
    }

    var linkDirection = document.getElementById(linkDirection);
    linkDirection.target = "_blank";
    linkDirection.href='https://www.google.com/maps/dir//'+lat+','+lng+'/@'+lat+','+lng+',18z'; // ?hl=en-US
}


